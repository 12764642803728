.App {
  text-align: center;
}
.modal-lg, .modal-xl {
  --bs-modal-width: 920px !important;
}
.cursorPointCls {
  cursor: pointer;
  float: none;
  color: #FFF;
  padding: 5px 12px;
  text-decoration: none;
  display: block;
  text-align: left;
}
.text-center p{
  font-size: 13px;
}
.card {
  border: 1px solid #FFF;
  background: #e6f4fd !important;
  min-height: 100vh;
}
.card-header {
  background-color: rgba(0, 0, 0, 0) !important;
  border-bottom: none !important;
}
.card-sec {
  padding-left: 0px !important;
}
button.btn-primary {
  background: #17abab !important;
  border: 0 !important;
}

@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
.countCards {
  width: 100% !important;
}
button.clrSearch {
  font-size: 14px !important;
  text-decoration: none;
  color: #17abab;
}

.btn-info {
  background-color: #17abab !important;
}

a:active {
  color: none !important;
}

.alignleft {
  float: left;
}

.alignright {
  float: right;
}

.searchCol {
  max-width: 54px;
  width: 100%;
}

.filterCol {
  max-width: 335px;
  width: 100%;
  border-top: 1px solid #DCDCDC;
  border-bottom: 1px solid #DCDCDC;
  border-right: 2px solid #17abab !important;
  padding-left: 10px;
  padding-right: 10px;
}

/* SIDEBAR BUTTON CSS */
.list-group-item {
  border: 1px solid #FFF;
  padding: 0px !important;
  margin-top: -4px;
}

.btnCls {
  padding: 18px 15px -1px 10px;
  position: relative;
  width: 50px;
  height: 40px;
  text-decoration: none;

}

.btnCls::before {
  content: '';
  height: 6px;
  right: 13px;
  position: absolute;
  width: 6px;
  top: 10px;
}

.btnCls::after {
  border-bottom: 13px solid transparent;
  border-left: 10px solid #17abab;
  border-top: 13px solid transparent;
  content: '';
  position: absolute;
  right: -6px;
  top: 8px;
}
.page {
  text-align: center;
}

#cover-spin {
  position: fixed;
  margin-left: 390px;
  left: 0%;
  right: 0%;
  top: 0%;
  bottom: 1%;
  background-color: rgba(51, 51, 51, 0.3);
  z-index: 99999;
}

#cover-spin::after {
  content: '';
  display: block;
  position: absolute;
  left: 48%;
  top: 40%;
  width: 80px;
  height: 80px;
  border-style: solid;
  border-color: #DCDCDC;
  border-top-color: transparent;
  border-width: 4px;
  border-radius: 50%;
  -webkit-animation: spin .8s linear infinite;
  animation: spin .8s linear infinite;
}
.tgs-saga-font-bold {
  font-weight: bold
}
.clrSearch:hover {
  color: #17abab !important;
}
.uwiErr {
  width: 285px;
  color: #f93154;
  margin-top: -0.75rem;
  font-size: 12px;
  text-align: left;
  margin-left: 14px;
  line-height: 18px;
}
.uwicardInside {
  width: 300px;
  height: 100px;
  line-height: 35px;
  margin: 10rem auto;
}
.MuiTabPanel-root{
  padding: 0px !important;  
}
.MuiTab-textColorPrimary{
  line-height: 0.95 !important;
  min-height: 35px !important;
}
.MuiChip-root{
  margin: 1px !important;
}
.MuiTabs-flexContainer button{
  border: 1px solid gray !important;
    border-radius: 7px;
    color: #17abab !important;
}
.MuiTabs-indicator{
  background:none !important
}