body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
button {
  font-weight: normal !important;
}

h6 {
  display: inline-flex;
}
.tablebox {
  width: 100%;
  background-color: #F7F7F7;
  margin: 0 auto 10px;
  border-radius: 2px;
  line-height: 30px;
  cursor: auto;
  padding-bottom: 5px;
}

.tablebox label {
  float: left;
  width: 100%;
  margin-left: 13px;
  margin-top: 10px;
  margin-bottom: 2px;
  font-weight: bold;
  display: inline-flex !important;
}

@-moz-document url-prefix() {
  textarea {
    width: 18em;
  }
}

.tablebox textarea {
  display: block;
  margin-left: 0.8em !important;
  border-color: lightgray !important;
}

.uploadFile {
  cursor: pointer;
  color: #17abab;
  position: relative;
  bottom: 2.05rem;
  left: 6.05rem;
  font-weight: normal !important;
}

#upload {
  opacity: 0;
  position: absolute;
  z-index: -1;
}
.autocomplete-textfield-root {
 
 .MuiSvgIcon-root {
    color: #000 !important;
 }
 
::placeholder {
  color:    #C0C0C0 !important;
}

}